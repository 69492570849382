.contenedorNamePriceButton {
  font-family: "Montserrat";
}

.contenedorNamePriceButton .name {
  font-size: 47px;
}

.contenedorNamePriceButton .price {
  font-size: 24px;
  font-weight: 700;
  color: #730075;
}

.slick-dots {
  display: flex !important;
  justify-content: center !important;
  cursor: pointer;
}

.slick-dots li {
  width: 100px;
  height: 50px;
  margin-right: 5px;
}

.carousel-container-image-grande {
  width: 100%;
  height: 0; /* Establecemos el height inicial en 0 */
  overflow: hidden;
  margin-bottom: 30px;
  padding-top: calc(
    9 / 16 * 100%
  ); /* Calculamos el padding-top para mantener la relación de aspecto 16:9 */
  position: relative;
}

.carousel-image-grande {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Puedes usar contain si prefieres ajustar la imagen sin recortar */
}

.carousel-image-menu-container {
  width: 100px;
  height: 60px;
  border: 2px solid #000;
  border-radius: 5px;
}

.carousel-image-menu-container.active {
  border: 2px solid #deb900;
  outline: 2px solid black;
}

.carousel-image-menu {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.numero-imagen-slider_txt {
  color: #a18d8d;
  font-weight: 600;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: black;
}

.slick-prev:before {
  margin-left: -30px;
}
.slick-next:before {
  margin-right: -30px;
}

.plotter-txt-container {
  margin-top: 10px;
}

.plotter_txt {
  text-decoration: underline;
  cursor: pointer;
}

/* Responsive */
@media screen and (max-width: 599px) {
  .contenedorNamePriceButton .name {
    font-size: 30px;
  }
}
