.productsSelectedContenedor {
  height: 100px;
  background-color: white;
}

/* Estructurar con flexbox las cajitas de los productos seleccionados */
.contenedorComponents {
  display: flex;
}

.contenedorComponents div {
  align-self: center;
}

.reservar-section-img {
  width: 90px;
  height: 60px;
  object-fit: cover;
}
