.seguir-reservando-btn {
  color: #fff !important;
}

.seguir-reservando-btn:hover {
  background-color: #52195a !important;
}

.navbar-payment {
  background-color: #730075;
  height: 80px;
  display: flex;
}
