.zonas-de-cobertura {
  background: linear-gradient(to right, #844fb9 50%, #c6ecef 50%);
  color: #fff;
  font-weight: normal;
  display: flex;
  height: 377px;
  font-size: 15px;
}

.zonas-de-cobertura h3 {
  color: #fff;
  font-weight: normal;
  font-size: 28px;
  font-family: "PT Sans Narrow", sans-serif;
  margin: 0px;
  padding-top: 15px;
}

.zonas-de-cobertura ul {
  padding-left: 0px;
  padding-right: 30px;
  font-family: Arial, Helvetica, sans-serif;
  float: left;
}

.zonas-de-cobertura ul li {
  line-height: 22px;
  padding-left: 10px;
  background: url(../../../assets/images/otros/li-square.jpg) left 50% no-repeat;
  list-style: none;
}

.zonas-de-cobertura img {
  height: 377px;
  width: 529px;
}

.info-container {
  padding: 2px;
}

.info-items,
.mas-info {
  padding: 20px;
  border: #fff 2px solid !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}

.info-items {
  background-color: #97009a;
}

.mas-info {
  background-color: #844fb9;
}

.titulo-info {
  color: #fff;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 5px;
}

.subtitulo-info {
  text-align: center;
  color: #f4a063;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  max-width: 70%;
}

.leer-mas-btn {
  background-color: #e29b3f !important;
}

.text-icons-container {
  margin-bottom: 15px;
}

.text-icons-container p {
  font-size: 16px;
}

.terminosycondiciones-icons {
  width: 25px;
}
