.sliderImages {
  width: 100% !important;
  height: 398px;
  overflow: hidden;
  object-fit: cover;
}

.logo {
  position: absolute;
  width: 350px;
  top: 10px;
  left: 10px;
  z-index: 900;
}

.logo-responsive {
  position: absolute;
  width: 200px;
  top: 150px;
  left: 10px;
  z-index: 900;
}

.medalla {
  width: 170px;
  position: absolute;
  bottom: 15px;
  right: 10px;
  z-index: 900;
}

/* RESPONSIVE SLIDER */
.slider-container-responsive {
  position: relative;
  margin-bottom: 100vh;
}
.sliderImages-responsive {
  position: relative;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
