.navbar {
  background-color: #730075;
  height: 80px;
  display: flex;
  transition: top 0.3s ease; /* Transición suave para la propiedad 'top' */
}

/* navbar se pone pegajoso cuando se sobrepasa con el scroll */
.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 900;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ejemplo: sombra */
}

.logo-minimalista {
  width: 150px;
  cursor: pointer;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 10px;
}

.nav-responsive {
  overflow: auto;
  padding-bottom: 70px;
}

.nav-elements ul span,
.enlace-menu-responsive {
  font-weight: 400;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #fff;
  text-decoration: none;
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que excede el ancho */
  width: 0; /* Inicia con ancho cero */
  animation: revealText 0.5s forwards; /* Anima el ancho para revelar el texto */
}

.nav-elements ul span {
  padding: 15px;
  font-size: 22px;
}

.enlace-menu-responsive {
  margin-left: 10px;
  font-size: 19px;
  padding: 5px;
  border-bottom: 1px solid #fff;
}

@keyframes revealText {
  to {
    width: 100%; /* Ancho completo al final de la animación */
  }
}

.active-scroll-spy span {
  background-color: #deb900 !important;
  border-radius: 5px;
}

.active-scroll-spy > span {
  color: #730075 !important;
}

.carritoIcon {
  color: #deb900;
}

.orange-bar {
  height: 1px;
  background-color: orange;
  border: none;
  margin: 10px 0;
}

.icons {
  color: #fff;
  cursor: pointer;
}

@media (max-width: 899px) {
  /* Tamaño de pantalla sm y xs */
  .sticky {
    background-color: #730075 !important;
  }
  .carritoIcon {
    color: #fff;
  }
  .sticky .carritoIcon {
    color: #deb900;
  }
  .navbar {
    color: #fff;
    background-color: transparent;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 900;
  }
  .navbar .headerContainer {
    height: 100vh;
  }

  .active-scroll-spy span {
    background-color: transparent !important;
    border-radius: 0px;
  }

  .active-scroll-spy > span {
    color: #fff !important;
  }

  .nav-elements ul {
    justify-content: none;
  }
}
