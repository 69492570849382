.button {
  background-color: #deb900;
  padding: 15px;
}

.button:hover {
  background-color: #e29b46;
}

.red-day {
  color: red;
  background-color: pink;
}

/* PEDIDOS PENDIENTES */

.aceptar-pedido-btn .rechazar-pedido-btn {
  color: #fff !important;
  padding: 10px 25px !important;
  font-size: 18px !important;
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
}

.aceptar-pedido-btn {
  background-color: #22b712 !important;
}

.rechazar-pedido-btn {
  background-color: #e41111 !important;
}

.aceptar-pedido-btn:hover {
  background-color: #1ca10d !important;
}

.rechazar-pedido-btn:hover {
  background-color: #d31212 !important;
}

.texto-resaltado-dialog-verde {
  font-weight: 700;
  color: #1ca10d;
}

.texto-resaltado-dialog-rojo {
  font-weight: 700;
  color: #b31010;
}

.dialog-todos-los-datos-container {
  margin-top: 20px;
  overflow: auto;
}

.dialog-datos-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.violet-bar {
  height: 1px;
  background-color: #730075;
  border: none;
}

/* DIAS DESHABILITADOS */

.container-diasDeshabilitados {
  background-color: #730075;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 5px;
}

.aclaracion-txt {
  color: #fff;
  font-size: 25px;
}

/* INFLABLES DESHABILITADES */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 30vh;
}

.celdaDeshabilitada {
  background-color: #d4422c;
}

/* ADMIN AUTH LOGIN */

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.login-form input {
  margin-bottom: 10px;
  padding: 8px;
}

.login-form button {
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #1062b4;
}

.login-form .login_btn {
  padding: 8px 16px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
