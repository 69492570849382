.fondo-filtros {
  background-color: #730075;
  border-radius: 5px;
  padding: 10px;
}

.todos-los-resultados-txt {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: #730075;
  font-size: 16px;
}

.filtro-titulo,
.filtro-opcion {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.filtro-titulo {
  font-size: 22px;
  color: #edb86f;
}

.filtro-opcion {
  font-size: 18px;
  color: #fff;
}

.filtro-sub-opcion {
  margin-left: 65px;
}

.filtro-opcion span {
  margin-left: 5px;
}

.hidden {
  display: none;
}

.filtro-responsive {
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: #730075;
  width: 0px !important;
  max-width: 600px;
  height: calc(100vh);
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 910;
}

.filtro-responsive.active {
  width: 100% !important;
}

.button-filtrar-responsive_txt {
  color: #730075 !important;
}

.button-filtro-responsive-container {
  background-color: #deb900;
  bottom: 0;
  left: 0;
  z-index: 80;
  padding: 8px;
  cursor: pointer;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.body-scroll-disabled {
  overflow: hidden !important;
}
