.corporative-header-container {
  background-color: var(--secondary);
  height: 96px;
}

.corporative-header-container img {
  width: 150px;
  position: absolute;
  top: 13px;
  left: 30px;
  cursor: pointer;
}
