.corporative-card-container {
  background-color: var(--secondary);
  position: relative;
  border: 2px solid #fff;
}

.corporative-card-container .event-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4/3;
}

.corporative-card-container .organizer-logo-container {
  position: absolute;
  border-radius: 50px;
  bottom: 40px;
  right: 0;
  width: 20%;
  aspect-ratio: 1;
  overflow: hidden;
  border: 2px solid #fff;
}

.corporative-card-container .organizer-logo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.corporative-card-details {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.corporative-card-details p,
.corporative-card-details span {
  font-size: 17px;
}
.corporative-card-details p {
  color: #fff;
}

.corporative-card-details span {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  border-top-left-radius: 10px;
  color: #333;
  background-color: var(--tertiary);
  align-self: flex-end;
}
