@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=PT+Sans+Narrow&display=swap");

:root {
  --color-primario: #730075;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 18px;
}
