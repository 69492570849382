.contacto {
  background-color: #3be200;
  padding: 35px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #730075;
}

.contacto h3 {
  color: #fff;
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 400;
  font-size: 37px;
  margin-bottom: 5px;
}

.contacto h4 {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
}

.contacto span,
.contacto p {
  font-size: 19px;
  line-height: 25px;
}

.contacto span {
  font-weight: bold;
}

.contacto a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
}

.contacto .icon {
  margin-right: 4px;
}
