.contenedorReservarSection {
  top: 0px;
  right: -75px;
  background-color: white;
  width: 0px !important;
  max-width: 600px;
  height: 100vh;
  transition: all 0.2s ease-in;
  overflow-y: auto;
  padding: 2rem;
  position: fixed;
  font-family: "Montserrat", sans-serif;
}

.contenedorReservarSection.active {
  width: 85% !important;
  right: 0px;
}

.fondoOscuroTransparente {
  width: 100%;
  height: 5000px;
  top: 0px;
  background-color: black;
  position: absolute;
  z-index: 1000;
  opacity: 0.7;
  display: none;
}

.fondoOscuroTransparente.active {
  display: block;
}

.cerrar-button {
  background-color: #f5f5f5 !important;
  color: #676767 !important;
  padding: 5px 20px !important;
  font-size: 17px !important;
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
}

.cerrar-button:hover {
  background-color: #e4e4e4 !important;
}

.finalizar-reserva-button {
  background-color: #730075 !important;
  color: #fff !important;
  padding: 10px 25px !important;
  font-size: 18px !important;
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
  text-decoration: none !important;
}

.finalizar-reserva-button:hover {
  background-color: #452b5f !important;
}

.seguir-reservando-button {
  margin-top: 2rem !important;
  color: #730075 !important;
  border-radius: 0px !important;
  padding: 5px 20px !important;
  font-family: "Montserrat", sans-serif !important;
}

.valor-total {
  font-weight: 700;
}

.finalizar-reserva-button-disabled {
  padding: 10px 25px !important;
  font-size: 18px !important;
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
}

.sin-productos-txt {
  font-weight: 700;
  font-size: 16px;
  padding: 20px;
}
/* Responsive */
@media screen and (max-width: 599px) {
  .contenedorReservarSection {
    width: 100%;
  }
}
