.tienda-container {
  background-color: #3be200;
  margin-top: 2px;
}

.tienda-container .calendar-city-container,
.texto-filtro-responsive-container {
  background-color: #730075;
  display: "flex";
  padding: 10px;
  border-radius: 5px;
  justify-content: space-between;
}

/* Texto de inputs */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1v4ccyo,
.css-p0rm37,
.css-1x5jdmq, .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
}

/* Borde de inputs */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-igs3ac {
  border-color: #fff !important;
}

/* Borde de inputs */
.inputRed .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.inputRed .css-igs3ac {
  border-color: #ff0000 !important;
}

/* Label de inputs */
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root,
.css-1ald77x {
  color: #fff !important;
}

/* Icono flecha de input Ciudad */
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-1636szt,
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
.css-1yhq19e {
  color: #fff !important;
}

/* Estilo de los DialogAlerts */
.dialog-container {
  background-color: #ecaf5f;
  border: dashed 3px #730075;
}

.texto-resaltado-dialog {
  font-weight: 700;
  color: #730075;
}

.texto-resaltado-naranja {
  font-weight: 700;
  color: #eda548;
}

.button-dialog {
  color: #3be200;
}

.overflow-hidden {
  overflow: hidden;
}

.sin-resultados-container {
  text-align: center;
}

.sin-resultados-texto1 {
  font-size: 35px;
}
.calendar-city-container .sin-resultados-texto2 {
  font-size: 25px;
}

.responsive-filtro-texto1,
.responsive-filtro-texto2 {
  font-style: italic;
}

.responsive-filtro-texto1 {
  font-size: 25px;
}

.responsive-filtro-texto2 {
  font-size: 20px;
}
