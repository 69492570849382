.headerContainer {
  height: 483px;
}

.nav-responsive {
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #730075;
  width: 0px !important;
  max-width: 70%;
  height: calc(100vh);
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.nav-responsive.active {
  width: 100% !important;
}

.fondo-oscuro {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.fondo-oscuro.active {
  display: block;
}

.body-scroll-disabled {
  overflow: hidden;
}

.nav-responsive ul {
  display: flex;
  align-items: center;
  padding-left: 0;
  flex-wrap: wrap;
}

.nav-responsive ul a {
  display: inline-block;
}

.nav-responsive ul li {
  margin-top: 30px;
  width: 100%;
}

.nav-responsive ul li {
  margin-top: 22px;
}

.container {
  display: flex !important;
  justify-content: space-between !important;
}

@media (max-width: 899px) {
  /* pantalla sm y xs */
  .headerContainer {
    height: 100vh;
  }
}
