html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.container-inputs {
  background-color: #730075;
  padding: 20px;
  border-radius: 5px;
  padding: 30px;
}

/* Flecha de select color */
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon,
.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon,
.css-1utq5rl {
  color: #fff !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled,
.css-mnn31.Mui-disabled {
  -webkit-text-fill-color: #ccc !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled,
.css-348fk2.Mui-disabled {
  color: white !important;
}

.products-name,
.products-price,
.date-payment,
.advertencia-text {
  font-weight: 700;
}

.products-name,
.products-price {
  color: #fff;
}
.advertencia-text {
  color: #730075;
}
.date-payment {
  color: #deb900;
  margin-bottom: 10px;
}
.finalizar-reserva-button-payment .finalizar-reserva-button-payment-disabled {
  padding: 10px 25px !important;
  font-size: 18px !important;
  border-radius: 0px !important;
  font-family: "Montserrat", sans-serif !important;
}
.finalizar-reserva-button-payment {
  background-color: #deb900 !important;
  color: #000 !important;
}

.finalizar-reserva-button-payment-disabled {
  background-color: #c47712 !important;
  color: #434040 !important;
}

.finalizar-reserva-button-payment:hover {
  background-color: #e49328 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.resumen-responsive {
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-color: #730075;
  height: 0px !important;
  width: calc(100vw);
  transition: all 0.2s ease-in;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 910;
}

.resumen-responsive.active {
  height: 100% !important;
}

.button-resumen-responsive-container {
  background-color: #deb900;
  bottom: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  padding: 8px;
  cursor: pointer;
}

.button-resumen-responsive_txt {
  color: #730075 !important;
}

.total-resumen-responsive_txt,
.precio-total-resumen-responsive_txt {
  font-family: "Montserrat", sans-serif !important;
}
.total-resumen-responsive_txt {
  font-size: 22px;
  font-weight: 700;
}

.precio-total-resumen-responsive_txt {
  font-size: 29px;
}
