.CardMedia {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 250px;
  position: relative;
  z-index: 2;
}

.CardMedia.hidden {
  visibility: hidden;
}

.product-image-container {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.deshabilitado .CardMedia {
  filter: grayscale(0.7);
}

.CardMedia.image-loaded {
  opacity: 1;
}

.MuiCardHeader-title {
  font-size: 21.5px !important;
}

.carrito-ver_txt {
  font-size: 20px;
}

.habilitado {
  background: linear-gradient(to top, #730075 80.5%, #deb900 18.5%) !important;
  outline: dashed 2px #fff;
  box-shadow: 0 0 0 5px #730075 !important;
}

.deshabilitado {
  background: linear-gradient(to top, #535053 80.5%, #e3d1b9 18.5%) !important;
  outline: dashed 2px #fff;
  box-shadow: 0 0 0 5px #535053 !important;
}

.seleccionado {
  background: linear-gradient(to top, #098a1a 80.5%, #a6ff52 18.5%) !important;
  outline: dashed 2px #fff;
  box-shadow: 0 0 0 5px #098a1a !important;
}

.habilitado:nth-child(3) {
  display: none !important;
}

.habilitado .reservado2,
.habilitado .noreservado2,
.reservado,
.deshabilitado .noreservado,
.deshabilitado .reservado2,
.seleccionado .noreservado,
.seleccionado .reservado2 {
  pointer-events: none;
}

.habilitado .reservado,
.habilitado .noreservado {
  display: block !important;
}

.reservar-btn,
.ver-btn {
  background-color: #deb900 !important;
  border-radius: 5px !important;
}

.reservar-btn:hover,
.ver-btn:hover {
  background-color: #e29b3f !important;
}

.deshabilitado .reservar-btn,
.seleccionado .reservar-btn,
.deshabilitado-reservar-btn {
  pointer-events: none;
  background-color: #c8a77c !important;
  border-radius: 5px !important;
}

.deshabilitado-reservar-btn-div {
  cursor: not-allowed !important;
}

.css-46bh2p-MuiCardContent-root,
.css-1qw96cp {
  padding: 5px !important;
  padding-left: 5px !important;
}

.card-text-container {
  padding-top: 15px;
  padding: 10px;
}

.card-title {
  font-size: 19px !important;
  font-weight: 400 !important;
  line-height: 29px !important;
}

.card-disponible-txt {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
}

.card-price {
  font-size: 23px !important;
  font-weight: 400 !important;
  line-height: 29px !important;
  text-align: right;
}
