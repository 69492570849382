.footer {
  background-color: #3bba09;
  position: relative;
  height: 200px;
}

.globos-footer {
  position: absolute !important;
  bottom: 0px !important;
}
